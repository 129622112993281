.flickfuse-page{
    width:100%;
    background-color: black;
    padding-top:50px;
}
.userpt-frame .userframe-img img{
    background-size: cover;
    max-width:100%;
}
.solution-part{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15.9vw;
    background: #1E1E1E;
    padding:0 6.9vw;
    padding-top:11.5vw;
}
.flickfuse-page .hero-section .part5{
    padding-bottom:2.3vw;
}
.div2 img{
    background-size: cover;
    max-width:100%;
}
.solution-part div:first-child h1{
    color: var(--white, #FFF);
font-feature-settings: 'calt' off;
font-family: akira-expanded , sans-serif;
font-size: 3.45vw;
font-style: normal;
font-weight: 800;
line-height: normal;
letter-spacing: .11vw;
}
 .solution-part div:first-child p{
    color: rgba(255, 255, 255, 0.70);
font-feature-settings: 'calt' off;
font-family: Poppins;
font-size: 2.07vw;
max-width:100%;
font-style: normal;
font-weight: 400;
line-height: 160%; /* 51.2px */
letter-spacing: 0.07vw;
margin-top:1.61vw;
}
.solution-part img{
    background-size: cover;
    max-width:100%;
}
.svgs div img{
    background-size: cover;
    max-width:100%;
}
.svgs div:first-child div{
    padding:23vw 4.6vw 0px 4.6vw;
}
.svgs div:last-child div{
    padding-top:11.5vw;
}
.new-image img{ 
    background-size: cover;
    max-width:100%;
    padding-left:0;
}
.flickfuse-page .userpt-frame .heading {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5.29vw;
}
.flickfuse-page .userpt-frame .heading div:first-child div:first-child h1{
    color: var(--white, #FFF);
    font-feature-settings: 'calt' off;
    font-family: akira-expanded , sans-serif;
    font-size: 3.45vw;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 1.5px;
}
.flickfuse-page .userpt-frame .heading div:first-child div:last-child p{
    
color: rgba(255, 255, 255, 0.70);
margin-top:-1.61vw; 
font-feature-settings: 'calt' off;
font-family: Poppins;
font-size: 2vw;
font-style: normal;
font-weight: 400;
line-height: 160%; /* 51.2px */
letter-spacing: 0.32px;
}
.pain-points{
    background-size: cover;
    max-width:100%;
}
.flickfuse-page .userpt-frame{
    padding:6.9vw;
}
.flickfuse-page .svgs div:first-child {
    padding:11.5vw 2.3vw;
}

@media screen and (min-width:1080px){
    .flickfuse-page{
        padding-top:200px;
    }
    .flickfuse-page .hero-section .part3{
        padding-bottom:100px;
    }
    .flickfuse-page .userpt-frame .heading div:first-child div:first-child h1{
        font-size:50px;
    }
    .flickfuse-page .userpt-frame .heading div:first-child div:last-child p{
        font-size:32px;
        margin-top:-30px;
        width:100%;
    }
    .flickfuse-page .userpt-frame .heading{
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        gap: 64px;
        padding-top:50px;
    }
    .flickfuse-page .userpt-frame .heading div{
        padding-right:30px;
    }
    .solution-part{
        gap: 99px;
        padding:50px 100px 0 100px;
    }
    .solution-part div:first-child h1{
        font-size:50px;
    }
    .solution-part div:first-child p{
        font-size:32px;
    }
    .svgs div:first-child div{
        padding :100px 50px 100px 50px;
    }
    .svgs div:first-child .userflow{
        padding :100px 100px 100px 100px;
    }
    .svgs div:last-child div{
        padding-top:150px;
    }
}