@media screen and (min-width:1080px){
    .about-page{
        width:98vw;
        background-color: black;
    }
    .about-page .about-header{
        padding-top:70px;
    }
    .content-and-img img{
        height:920px;
        width:500px;
        border-radius: 23px;
        border:1px solid grey;
        margin-left:82px;
    }
     .about-page .main1{
        display: flex; 
        width: 735px;
        height: 1100px;
        padding: 25px;
        padding-top:150px;
        flex-direction: column;
        align-items: flex-start;
        gap: 13px;
    } 
    .about h3 span{
        color: rgba(255, 255, 255, 0.50);
    font-feature-settings: 'calt' off;
    font-family: Poppins;
    font-size: 23px;
    font-style: normal;
    font-weight: 500;
    line-height: 170.5%;
    letter-spacing: -0.23px;
    }
    .about-page .main1 .about{
        border-radius: 23px;
        border: 1px solid #FFF;
        background: rgba(48, 48, 48, 0.35);
        display: flex;
        width: 630px;
        height: 870px;
        padding: 25px;
        flex-direction: column;
        align-items: flex-start;
        gap: 13px;
        position: absolute;
        left:44%;
    }
    .about h2,h3{
        color: var(--white, #FFF);
    font-feature-settings: 'calt' off;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: 170.5%; 
    letter-spacing: -0.23px;
    }
    .about h1{
        color: var(--white, #FFF);
        font-feature-settings: 'calt' off;
        font-family: akira-expanded , sans-serif;
        font-size: 42px;
        font-style: normal;
        font-weight: 800;
        line-height: 150%; /* 63px */
        letter-spacing: 0.42px;
    }
    .about h2{
        margin-top:-23px;
        font-size: 28px;
    }
    .about h3{
        margin-top:-10px;
        font-size: 21px;
    }
    .about-part1{
        display: flex;
        margin-left:20px;
    }
    .about-part1 .bar{
        border-radius: 22px;
        background: rgba(217, 217, 217, 0.74);
        width: 390px;
        height: 1px;
        margin-left:90px;
        border-top:2px solid white;
    }
    .about-part1 h1{
        color: var(--white, #FFF);
        font-feature-settings: 'calt' off;
        font-family: akira-expanded ,sans-serif;
        font-size: 42px;
        font-style: normal;
        font-weight: 800;
        line-height: 150%; 
        letter-spacing: -0.42px;
        margin-top:-28px;
        margin-left:45px;
    }
    .about-part1 h1 span:first-child{
        margin-right:10px;
    }
    
    .about-part1 .bar1{
        border-radius: 22px;
    background: rgba(217, 217, 217, 0.74);
    width: 400px;
    height: 1px;
    margin-left:50px;
    border-top:2px solid white;
    }
    .about-part2{
        margin-left:62px;
    }
    .about-part2 img{
        margin:30px -40px 50px -33px;
    }
    .about-part3 {
        width:100%;
        height:1px;
        border-top:1px solid white;
    }
    .about-part4 p{
    
    text-align: center;
    font-feature-settings: 'calt' off;
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 34.5px */
    letter-spacing: -0.23px;
    color:white;
    }
    .about-part2{
        display: flex;
        align-items: flex-start;
        padding-left:20px;

    }
    .about-part2 a {
        text-decoration: none;
    }
    .about-part2 a p {
        margin-top:-100px;
        margin-left:70px;
        margin-bottom:120px;
        color: #FFF;
        font-feature-settings: 'calt' off;
        font-family: akira-expanded , sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 150%; /* 30px */
        letter-spacing: 1.2px;
    }
    
    
}
@media screen and (max-width:1080px){
    .about-page{
        width:100vw;
        background-color: black;
    }
    .about-page .about-header{
        padding-top:70px;
    }
    .content-and-img img{
        height:900px;
        width:530px;
        border-radius: 23px;
        border:1px solid grey;
    }
    .about h2{
        margin-top:-23px;
    }
    .about h3{
        margin-top:-10px;
    }
    .about-part1{
        display: flex;
    }
    .about-part1 .bar{
        border-radius: 22px;
    background: rgba(217, 217, 217, 0.74);
    width: 420px;
    height: 1px;
    margin-left:80px;
    }
    .about-part1 h1{
        color: var(--white, #FFF);
    font-feature-settings: 'calt' off;
    font-family: akira-expanded , sans-serif;
    font-size: 42px;
    font-style: normal;
    font-weight: 800;
    line-height: 150%; 
    letter-spacing: -0.42px;
    margin-top:-28px;
    margin-left:45px;
    }
    
    .about-part1 .bar1{
        border-radius: 22px;
    background: rgba(217, 217, 217, 0.74);
    width: 420px;
    height: 1px;
    margin-left:50px;
    }
    .about-part2{
        margin-left:50px;
    }
    .about-part2 img{
        margin:30px -30px 50px -30px;
    }
    .about-part3 {
        width:100%;
        height:1px;
        border-top:1px solid white;
    }
    .about-part4 p{
    
    text-align: center;
    font-feature-settings: 'calt' off;
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 34.5px */
    letter-spacing: -0.23px;
    color:white;
    }
    .about-part2{
        display: flex;
        align-items: flex-start;
    }
    .about-part2 a {
        text-decoration: none;
    }
    .about-part2 a p {
        margin-top:-100px;
        margin-left:70px;
        margin-bottom:120px;
        color: #FFF;
        font-feature-settings: 'calt' off;
        font-family: akira-expanded , sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 150%; /* 30px */
        letter-spacing: 1.2px;
    }
    .about-page{
        background-size: cover;
        /* height:351vh; */
    }
    .about-page .main1{
        display:flex;
        flex-direction: column;
        justify-content:center;
        align-items:center;
    }
    .content-and-img img{
        background-size:cover;
        max-width:100vw;
        height:49vh;
        width:46vw;
        margin-top:23vw;
    }
    .about-page .main1 .about{
        display: flex;
        width:73vw;
        margin-top:50px;
        height: 62.52vh;
        padding: 5.7vw;
        flex-direction: column;
        align-items: flex-start;
        gap: 13px;
        border-radius: 23px;
        border: 1px solid #FFF;
        background: rgba(48, 48, 48, 0.35);
    }
    .about-page .main1 .about h1{
        color: var(--white, #FFF);
        font-feature-settings: 'calt' off;
        font-family: akira-expanded , sans-serif;
        font-size: 5.3vw;
        font-style: normal;
        font-weight: 800;
        line-height: 150%; /* 63px */
        letter-spacing: -0.42px;
    }
    .about-page .main1 .about h2{
        color: var(--gray2, #CDCDCD);
        font-feature-settings: 'calt' off;
        font-family: Poppins;
        font-size: 3.45vw;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: -0.3px;
    }   
    .about-page .main1 .about h3{
        color: var(--gray2, #CDCDCD);
        font-feature-settings: 'calt' off;
        font-family: Poppins;
        font-size: 3vw;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: -0.3px;
    }
    .about-page .main1 .about h3 span{
        color: rgba(255, 255, 255, 0.50);
        font-feature-settings: 'calt' off;
        font-family: Poppins;
        font-size: 3vw;
        font-style: normal;
        font-weight: 500;
        line-height: 170.5%;
        letter-spacing: -0.23px;
    }
    .about-part1{
        margin-top:50px;
        margin-left:-80px;
        display:flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .about-part1 div{
        border-top:1px solid white;
    }
    .about-part1 h1{
        font-size:3.45vw;
        font-family:akira-expanded , sans-serif;
        display:flex;
        margin-top:10px;
    }
    .about-part1 h1 span:first-child{
        margin-right:10px;
    }
    .about-part1 .bar{
        width:22%;
        margin-right:-30px;
    }
    .about-part1 .bar1{
        width:23%;
        margin-left:10px;
    }
     .about-part2{
        display: flex;
        flex-wrap: wrap;
        margin-left:-10px;
        justify-content: center;
        align-items: center;
     }
     .about-part2 a{
        margin:-50px 0px;
        margin-bottom:-100px;
     }
     .about-part3{
        margin-top:52px;
     }
     .about-part4 p{
        font-size:2.76vw;
     }
}