.walls-page{
    width:100%;
    background-color: black;
    padding-top:50px;
}
.userpt-frame .userframe-img img{
    background-size: cover;
    max-width:100%;
    padding-left:0;
    margin-left:0;
}
.solution-part{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 69px;
    background: #1E1E1E;
    padding:0 30px;
    padding-top:50px;
}
.walls-page .hero-section .part5{
    padding-bottom:10px;
}
.div2 img{
    background-size: cover;
    max-width:100%;
}
.solution-part div:first-child h1{
    color: var(--white, #FFF);
font-feature-settings: 'calt' off;
font-family: akira-expanded , sans-serif;
font-size: 15px;
font-style: normal;
font-weight: 800;
line-height: normal;
letter-spacing: 2.5px;
}
.solution-part div:first-child p{
    color: rgba(255, 255, 255, 0.70);
font-feature-settings: 'calt' off;
font-family: Poppins;
font-size: 9px;
max-width:100%;
font-style: normal;
font-weight: 400;
line-height: 160%; /* 51.2px */
letter-spacing: 0.32px;
margin-top:-7px;
}
.solution-part img{
    background-size: cover;
    max-width:100%;
}
.svgs div img{
    background-size: cover;
    max-width:100%;
}
.svgs div:first-child div{
    padding:100px 20px 0px 20px;
}
.svgs div:last-child div{
    padding-top:100px;
}
.new-image img{ 
    background-size: cover;
    max-width:100%;
    padding-left:0;
}
.walls-page .userpt-frame .heading {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 23px;
}
.walls-page .userpt-frame .heading div:first-child div:first-child h1{
    color: var(--white, #FFF);
    font-feature-settings: 'calt' off;
    font-family: akira-expanded , sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 1.5px;
}
.walls-page .userpt-frame .heading div:first-child div:last-child p{
    color: rgba(255, 255, 255, 0.70);
font-feature-settings: 'calt' off;
font-family: Poppins;
font-size: 9px;
max-width:100%;
font-style: normal;
font-weight: 400;
line-height: 160%; /* 51.2px */
letter-spacing: 0.32px;
margin-top:-7px;
}
.pain-points{
    background-size: cover;
    max-width:100%;
}
.walls-page .userpt-frame{
    padding:30px 30px;
}
.walls-page .solution-part div:last-child{
    padding-left:10px;
}
.walls-page .svgs div:first-child .userflow{
    padding-left:20px;
}
.walls-page .svgs div:first-child {
    padding-right:20px;
}

@media screen and (min-width:1080px){
    .walls-page{
        padding-top:200px;
    }
    .walls-page .hero-section .part3{
        padding-bottom:100px;
    }
    .walls-page .userpt-frame .heading div:first-child div:first-child h1{
        font-size:50px;
    }
    .walls-page .userpt-frame .heading div:first-child div:last-child p{
        font-size:32px;
        margin-top:-30px;
        width:100%;
    }
    .walls-page .userpt-frame .heading{
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        gap: 64px;
        padding-top:50px;
    }
    .walls-page .userpt-frame .heading div{
        padding-left:40px;
        padding-right:30px;
    }
    .solution-part{
        gap: 99px;
        padding:50px 100px 0 100px;
    }
    .walls-page .solution-part div:last-child{
        padding-left:50px;
    }
    .solution-part div:first-child h1{
        font-size:50px;
    }
    .solution-part div:first-child p{
        font-size:32px;
    }
    .svgs div:first-child div{
        padding :100px 60px 100px 50px;
    }
    .walls-page .svgs div:first-child .userflow{
        padding :100px 60px 100px 50px;
    }
    .walls-page .svgs div:last-child div:last-child{
        padding-top:200px;
    }
}