@font-face {
    font-family: "akira-expanded";
    src: url("../Fonts/AkiraExpanded-SuperBold/Akira_Expanded_Demo.woff");
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Poppins:wght@300;500;600&display=swap');

body {
    background-color:black;
    margin: 0;
    padding: 0;
    width:100%;
}

.container_1 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section {
    background-color: black;
}

.home {
    padding-top: 10%;
    background: url("home.png"), black 50% / cover no-repeat;
    background-blend-mode: hard-light;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 46vw 0px;
}

.home .main h1 {
    color: var(--white, #FFF);
    text-align: center;
    font-feature-settings: 'calt' off;
    font-family: akira-expanded, sans-serif;
    font-style: normal;
    font-weight: 800;
    line-height: 99.039%;
    font-size:3.3vw;
    max-width: 100%;
    letter-spacing: 1px;
}

.main h1 span {
    display: block;
}

.part3 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 8vw 16vw 8vw;
}

.part3 h1 {
    color: #FFF;
    font-feature-settings: 'calt' off;
    font-family: akira-expanded, sans-serif;
    font-size: 5.75vw;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -0.66px;
}

.part3 p {
    color: rgba(255, 255, 255, 0.70);
    font-feature-settings: 'calt' off;
    font-family: Poppins;
    font-size: 2.3vw;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.115px;
    width: 59.34vw;
    margin-top: -4.6vw;
}

.part2 img {
    background-size: cover;
    max-width: 100vw;
}

.part3 div:last-child img {
    height: 4.6vw;
    width: 10.23vw;
    margin-left: 11.5;
}

.part7 {
    padding-bottom: 9.2vw;
}

.part8 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
    padding-left: 7.82vw;
}

.part8 div {
    display: flex;
    padding: .46vw 3.45vw;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 7px;
    background: #464646;
    margin-top: -1.15vw;
}

.part8 h1 {
    color: rgba(255, 255, 255, 0.80);
    font-feature-settings: 'calt' off;
    font-family: akira-expanded, sans-serif;
    font-size: 2.3vw;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -.05vw;
} 
.part8 div a {
    color: #FFF;
    font-family: Poppins;
    font-size: 2.3vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.04vw;
    text-decoration-line: underline;
}

.part9 {
    text-align: center;
    display: flex;
    padding-top: 11.5vw;
}

.part9 h3 {
    font-size: 2.07vw;
    padding-left: 7.44vw;
    color:white;
    font-family: Poppins;
}

.part9 a {
    font-size: 2.3vw;
    color: rgba(255, 255, 255, 0.60);
    text-align: center;
    text-decoration: none;
    font-feature-settings: 'calt' off;
    font-family: Inter;
    font-style: italic;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.2px;
    padding-top: 2.3vw;
    padding-left: 35%;
}

.main p {
    color: var(--white, #FFF);
    font-feature-settings: 'calt' off;
    font-family: akira-expanded, sans-serif;
    font-size: 2.3vw;
    font-style: normal;
    font-weight: 800;
    line-height: 150%;
    letter-spacing: 1.45px;
    margin-bottom: -5px;
}
.picasso-section{
    padding-top:4.6vw;
}
.picasso-div{
    margin-top:-4.6vw;
}


@media screen and (min-width:1080px) {
    section {
        background:black;
    }

    .main {
        padding-top: 250px;
    }

    .home {
        padding-top: 70px;
    }

    .home .main h1 {
        font-size: 50px;
        letter-spacing: 1.45px;
    }

    .main p {
        font-size: 15px;
        margin-bottom: -23px;
    }

    .part3 h1 {
        font-size: 66px;
        letter-spacing: 1px;
        padding-left:80px;
    }

    .part3 {
        padding: 2rem;
        padding-bottom: 250px;
    }

    .part3 p {
        font-size: 23px;
        width: 598px;
        margin-top: -60px;
        padding-left:80px;
    }

    .part3 div:last-child img {
        margin-top: 0px;
        height: 55px;
        width: 10vw;
        padding-right:80px;
    }

    .part7 {
        padding-bottom: 90px;
    }

    .part8 {
        padding-left: 100px;
    }

    .part8 div {
        padding: 5px 10px;
        margin-top: -5px;
    }

    .part8 h1 {
        font-size: 23px;
        letter-spacing: -0.23px;
    }

    .part8 div a {
        font-size: 19px;
        text-decoration-line: underline;
    }

    .part9 {
        display: flex;
        padding-left: 32.5%;
        padding-top: 92px;
    }

    .part9 h3 {
        font-size: 20px;
    }

    .part9 a {
        font-size: 22px;
        padding-top: 18px;
        padding-left: 34%;
    }

}